import { Status } from "../../database";

export const AllArticles = (status = Status.PUBLISHED, first: number, skip: number) => `
    query Articles {
    articlesConnection(first: ${first}, skip: ${skip}) {
        aggregate {  
            count  
        } 
    }
      articles(first: ${first}, skip: ${skip}) {
        ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
      }
    }
`;

export const SurroundingArticles = (ref: string, createdAt: Date) => `
  query GetSurroundingArticles {
    articlesBefore: articles(
      where: { AND: [{ createdAt_lt: "${createdAt.toISOString()}" }, { ref_not: "${ref}" }] }
      orderBy: createdAt_DESC
      first: 1
    ) {
      ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
    }
    articlesAfter: articles(
      where: { AND: [{ createdAt_gt: "${createdAt.toISOString()}" }, { ref_not: "${ref}" }] }
      orderBy: createdAt_ASC
      first: 1
    ) {
       ref
        path
        coveredLocale
        createdAt
        id
        publishedAt
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
    }
  }
`;

export const SingleArticle = (status = Status.PUBLISHED) => `
    query Article {
      article ${status === Status.DRAFT ? '(stage: DRAFT)' : ''} (where: {path: $path}) {
        coveredLocale
        createdAt
        id
        path
        publishedAt
        ref
        seo
        updatedAt
        title
        description
        content
        image
        category {
            name
            path
            coveredLocale
        }
        author {
            firstname
            lastname
            twitterAccount
            avatar
            role
            path
            coveredLocale
        }
      }
    }
`;